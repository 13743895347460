import {Component, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {DuoSchool, PhoneNumber, SchoolOfOrigin, SignUp} from '../../shared/model/signup';
import {SchoolAutocompleteService} from '../../shared/services/schoolautocomplete.service';
import {SignUpService} from "../../shared/services/signup.service";
import {TextFormatService} from "../../shared/services/textformat.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormService} from "../../shared/services/form.service";
import {FormType, SectionType} from "../../shared/model/formSection";
import {AutoCompletor, ConditionType, FormField} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";
import {CountryService} from "../../shared/services/country.service";
import {CountryCode} from "../../shared/model/countrycode";

@Component({
  selector: 'app-previous-school-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class PreviousSchoolDynamicComponent extends DynamicSectionComponent {
  @Input()
  checking: boolean = false;

  signUp: SignUp;
  previousSchool: SchoolOfOrigin = {
    schoolType: 'PO',
    country: '6030',
    contactPhone: {
      isSecret: '',
      number: ''
    } as PhoneNumber
  } as SchoolOfOrigin;
  countryCodes: CountryCode[] = [
    {
      countryCode: '6030',
      countryName: 'Nederland'
    }
  ];
  schoolCompletions: DuoSchool[] = [];

  constructor(router: Router,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              formService: FormService,
              private signUpService: SignUpService,
              private autocompleteService: SchoolAutocompleteService,
              private textFormatService: TextFormatService,
              private countryService: CountryService,
              modalService: BsModalService) {

    super(router,formService,notificationService, modalService, ruleEvaluatorService, SectionType.PREVIOUSSCHOOL, FormType.SIGNUP);

    this.formGroup = this.formBuilder.group({});

    this.countryService.getCountries().subscribe((countries) => {
      this.countryCodes = countries;
      this.setCountryOptions(countries);
      this.init();
    });

  }

  init(): void {
    this.signUp = this.signUpService.getSignUp();

    if (this.signUp.schoolOfOrigin !== undefined && this.signUp.schoolOfOrigin !== null) {
      this.previousSchool = this.signUp.schoolOfOrigin;
    }
    this.initCustomQuestions(this.previousSchool);
    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      this.addCallbackFunctions(fld);
      this.checkControl(fld, this.previousSchool, this.signUp);
    });

    // When country changes, change schooltype according to country (ea buitenland or VO/PO (where educationyear and influx are checked to set))
    if (this.formGroup.get('country')) {
      this.formGroup.get('country').valueChanges.subscribe(value => {
        if (value == '6030') {
          if (this.signUp.school.educationYear > 1 || this.signUp.isInflux) {
            this.formGroup.get('schoolType').setValue("VO");
          } else {
            this.formGroup.get('schoolType').setValue("PO");
          }
        } else {
          this.formGroup.get('schoolType').setValue("buitenland");
        }
      })
    }
  }

  setCountryOptions(countries: CountryCode[]): void {
    let fld = this.getFieldByVarName('country');
    if (!fld) return;
    fld.options = this.countryService.getCountriesOptionsFromPreviousSavedCountries(countries);
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(c => {
      switch (c) {
        case ConditionType.PREVIOUS_VO:
          if (this.previousSchool.schoolType != 'VO') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PREVIOUS_ABROAD:
          if (this.previousSchool.schoolType != 'buitenland') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
      }
    })
    return checkOk;
  }

  addCallbackFunctions(fld: FormField): void {
    if (this.previousSchool.schoolType != 'buitenland') {
      if (fld.varName == 'city') {
        fld.changeFn = this.checkPreviousSchool;
      } else if (fld.varName == 'name') {
        fld.callbackFn = this.checkPreviousSchool;
      }
    }
    if (fld.varName == 'schoolType') {
      fld.changeFn = this.onSchoolTypeChange;
    }
    if (fld.varName == 'name') {
      let autoCompletor = new AutoCompletor();
      autoCompletor.inputFn = this.checkPreviousSchool;
      autoCompletor.fallBackFn = this.getPreviousSchools;
      autoCompletor.data = this.schoolCompletions;
      autoCompletor.onSelectFn = this.onSelect;
      autoCompletor.template = 'duoSchoolItemTemplate';
      autoCompletor.parent = this;
      fld.autoCompletor = autoCompletor;
    }
  }

  onSchoolTypeChange = (): void => {
    this.previousSchool.schoolType = this.getFormValueByVarName('schoolType');
    // reset everything
    this.previousSchool.brin = '';
    this.previousSchool.name = '';
    this.previousSchool.city = '';
    // reset school data for autocomplete
    this.getFieldByVarName('name').autoCompletor.data = [];
    this.schoolCompletions = [];

    this.checkControl(this.getFieldByVarName('explanation'), this.previousSchool, this.signUp)
    this.checkControl(this.getFieldByVarName('currentYear'), this.previousSchool, this.signUp)
    this.checkControl(this.getFieldByVarName('currentDepartment'), this.previousSchool, this.signUp)
    this.checkControl(this.getFieldByVarName('previousDepartments'), this.previousSchool, this.signUp)
    this.checkControl(this.getFieldByVarName('reasonSwap'), this.previousSchool, this.signUp)
    this.updateFormFields();
  }

  save(): boolean {
    if (!this.prepareSave(this.previousSchool)) return false;
    this.prepareCustomQuestionsForSave(this.previousSchool);

    if (this.previousSchool.contact) {
      this.previousSchool.contact = this.textFormatService.capitalizeFirstLetter(this.previousSchool.contact);
    }
    if (this.previousSchool.contactEmail) {
      this.previousSchool.contactEmail = this.previousSchool.contactEmail.toLowerCase();
    }

    this.signUpService.savePreviousSchool(this.previousSchool).subscribe((data) => {
      this.isSubmitted = false;
      this.goToNext(this.signUp);
    }, (err) => {
      this.getRouter().navigate(['/home']);
      this.handleSaveError(err);
    });

    return true;
  }

  checkPreviousSchool = (): void => {
    this.previousSchool.schoolType = this.getFormValueByVarName('schoolType');
    this.previousSchool.city = this.getFormValueByVarName('city');
    this.previousSchool.name = this.getFormValueByVarName('name');
    if (!this.previousSchool.city) {
      return;
    }

    this.autocompleteService.getAutocompleteSuggestions({
      schoolType: this.previousSchool.schoolType,
      cityName: this.previousSchool.city.toUpperCase(),
      schoolName: this.previousSchool.name?.toLowerCase(),
      filter: false
    }).subscribe((data: DuoSchool[]) => {
        this.getFieldByVarName('name').autoCompletor.data = data;
        this.schoolCompletions = data;
      },
      (data) => {
      });
  }

  getPreviousSchools = (): void => {
    this.previousSchool.schoolType = this.getFormValueByVarName('schoolType');
    this.previousSchool.city = this.getFormValueByVarName('city');
    this.previousSchool.name = this.getFormValueByVarName('name');
    if (!this.previousSchool.city) {
      return;
    }

    this.autocompleteService.getAutocompleteSuggestions({
      schoolType: this.previousSchool.schoolType,
      cityName: this.previousSchool.city?.toUpperCase(),
      schoolName: this.previousSchool.name?.toLowerCase(),
      filter: true
    }).subscribe((data: DuoSchool[]) => {
        if (data.length > 0) {
          this.getFieldByVarName('name').autoCompletor.data = data;
          this.schoolCompletions = data;
        }
      },
      (data) => {
      });
  }

  updateFormFields(): void {
    this.setFormValueByVarName('city', this.previousSchool.city);
    this.setFormValueByVarName('name', this.previousSchool.name);
    this.checkPreviousSchool();
  }

  onSelect(event, parentObj: any) {
    const selectedOption: DuoSchool = event.item;
    parentObj.previousSchool.name = selectedOption.displayName;
    parentObj.previousSchool.brin = selectedOption.brinNumber;
    parentObj.previousSchool.houseNumber = selectedOption.houseNumber;
    parentObj.previousSchool.postalCode = selectedOption.postalCode.replace(/\s*/g, '');
    parentObj.previousSchool.street = selectedOption.streetName;
    parentObj.updateFormFields();
  }
}
