<div class="wrapper">
  <app-header></app-header>

  <div class="mb-5"></div>

  <div class="bg-home">
    <div class="container">
      <div *ngIf="shouldShowSteps(); else noSteps">
        <div class="row">
          <div class="col-md-9 col-sm-12">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
          </div>
          <div class="col-md-3 hide-for-small-only">
            <app-stappenplan></app-stappenplan>
          </div>
        </div>
        <div class="mb-5"></div>
      </div>
    </div>
  </div>

  <div class="push"></div>
</div>

<app-footer></app-footer>

<!-- Template for homepage -->
<ng-template #noSteps>
  <router-outlet></router-outlet>
</ng-template>
