import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import { Family, SignUp } from "../../shared/model/signup";
import { SignUpService } from "../../shared/services/signup.service";
import { DynamicSectionComponent } from "../../shared/abstract/dynamic-section-component";
import { FormService } from "../../shared/services/form.service";
import {FormType, SectionType} from "../../shared/model/formSection";
import { ConditionType, FormField, SelectOption } from "../../shared/model/formField";
import { NotificationService } from '../../shared/services/notification.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";

@Component({
  selector: 'app-family-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class FamilyDynamicComponent extends DynamicSectionComponent implements OnInit {

  @Input()
  checking: boolean = false;

  signUp: SignUp;
  family: Family = {
    parentsDivorced: false,
    studentLivesAt: 'Beide',
    parentDied: false,
    parentalAuthority: 'Beide'
  } as Family;

  constructor(router: Router,
              private formBuilder: FormBuilder,
              formService: FormService,
              private signUpService: SignUpService,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              modalService: BsModalService) {
    super(router, formService, notificationService, modalService, ruleEvaluatorService, SectionType.FAMILY, FormType.SIGNUP)
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.signUp = this.signUpService.getSignUp();
    super.commonNgOnInit();
    if (!this.getSection()) return;

    if (!this.signUp) return;
    if (this.signUp.family !== undefined && this.signUp.family !== null) {
      this.family = this.signUp.family;
    } else {
      this.family.studentLivesAt = '';
      this.family.parentalAuthority = '';
    }

    // set defaults for parent 1 if no second caretaker
    if (!this.signUp.careTakers.secondCareTaker) {
      if (this.family.parentalAuthority?.toLowerCase() != 'anders') {
        this.family.parentalAuthority = "1";
      }
      if (this.family.parentalAuthority?.toLowerCase() != 'elders') {
        this.family.studentLivesAt = "1";
      }
    }

    this.initCustomQuestions(this.family);

    this.getSection().fields.forEach(fld => {
      this.addCallbackFunctions(fld);
      this.checkParentOptions(fld);
      this.checkControl(fld, this.family, this.signUp);
    });
  }

  addCallbackFunctions(fld: FormField): void {
    switch (fld.varName) {
      case 'studentLivesAt':
        fld.changeFn = this.onStudentLivesAtChange;
        break;
      case 'parentDied':
        fld.changeFn = this.onParentDiedChange;
        break;
      case 'parentalAuthority':
        fld.changeFn = this.onParentalAuthorityChange;
        break;
    }
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(c => {
      switch (c) {
        case ConditionType.LIVES_ELSEWHERE:
          if (this.family.studentLivesAt !== 'elders') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PARENT_DIED:
          if (!(this.family.parentDied.toString() == 'true')) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PARENTAL_AUTHORITY_NOT_BOTH:
          if (this.family.parentalAuthority.toLowerCase() == 'beide' || this.family.parentalAuthority == ''
              || (!this.signUp.careTakers.secondCareTaker && this.family.parentalAuthority.toLowerCase() != 'anders')) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          else {
            fld.conditionalDisabled = false;
          }
          break;
        case ConditionType.PARENTAL_AUTHORITY_OTHERWISE:
          if (!(this.family.parentalAuthority.toLowerCase() == 'anders')) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          else {
            fld.conditionalDisabled = false;
          }
          break;
      }
    })
    return checkOk;
  }

  checkParentOptions(fld: FormField): void {
    switch (fld.varName) {
      case "parentalAuthority":
      case 'studentLivesAt':
        if (this.signUp.careTakers.secondCareTaker) {
          this.checkParentOption(fld.options,"2","Ouder/verzorger 2");
          this.checkParentOption(fld.options,"beide","Beide");
        } else {
          this.skipOptionByValue(fld.options,"2");
        }
        if (this.signUp.careTakers.thirdCareTaker) {
          this.checkParentOption(fld.options,"3","Ouder/verzorger 3");
          this.skipOptionByValue(fld.options,"beide");
        } else {
          this.skipOptionByValue(fld.options,"3");
        }
        break;
      default:
    }
  }

  checkParentOption(options: SelectOption[], valToAdd: string, txtToAdd: string) {
    for (var i=0;i<options.length;i++) {
      if (options[i].value == valToAdd) {
        return;
      }
    }
    let insertAfter: string;
    if (valToAdd == "beide") {
      insertAfter = "2";
    } else {
      insertAfter = (parseInt(valToAdd) - 1).toString();
    }
    for (var i=0;i<options.length;i++) {
      if (options[i].value == insertAfter) {
       // insert new option here
        const option = new SelectOption(valToAdd,txtToAdd);
        options.splice(i+1,0,option);
        return;
      }
    }
  }

  skipOptionByValue(options: SelectOption[],val: string) {
    let index = -1;
    for (var i=0;i<options.length;i++) {
      if (options[i].value == val) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      options.splice(index,1);
    }
  }


  onStudentLivesAtChange = (): void => {
    this.family.studentLivesAt = this.getFormValueByVarName('studentLivesAt');
    this.checkControl(this.getFieldByVarName('studentLivesAtText'),this.family, this.signUp);
  }

  onParentDiedChange = (): void => {
    this.family.parentDied = 'true' == this.getFormValueByVarName('parentDied');
    this.checkControl(this.getFieldByVarName('whichParentDied'),this.family, this.signUp);
    this.checkControl(this.getFieldByVarName('deceasedNotes'),this.family, this.signUp);
  }

  onParentalAuthorityChange = (): void => {
    this.family.parentalAuthority = this.getFormValueByVarName('parentalAuthority');
    this.checkControl(this.getFieldByVarName('information.parentalAuthority'),this.family, this.signUp);
    this.checkControl(this.getFieldByVarName('parentalAuthorityExplanation'),this.family, this.signUp);
  }

  save(): boolean {
    if (!this.prepareSave(this.family)) return false;
    this.prepareCustomQuestionsForSave(this.family);

    this.signUpService.saveFamily(this.family).subscribe(() => {
      this.isSubmitted = false;
      this.goToNext(this.signUp)
    }, (err) => {
      this.getRouter().navigate(['/home']);
      this.handleSaveError(err);
    });

    return true;
  }

}
