import {FormDefinition} from "../model/formDefinition";
import {FormService} from "../services/form.service";
import {CustomQuestion, SignUp} from "../model/signup";
import {AppStappenplanComponent} from "../components/app-stappenplan";
import {FormSection, FormType, SectionType} from "../model/formSection";
import {Router} from "@angular/router";
import {ControlType, FieldType, FormField} from "../model/formField";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormRow} from "../model/formRow";
import {ValidateBSN} from "../validators/bsn.validator";
import {ValidateOWN} from "../validators/own.validator";
import {ValidateDate} from "../validators/date.validator";
import {ValidateIBAN} from "../validators/iban.validator";
import {ValidateConfirm} from "../validators/confirm.validator";
import {ValidateClusterDate} from "../validators/clustercheck.validator";
import {ValidateFutureDate} from "../validators/futuredate.validator";
import {ValidatePhone} from "../validators/phone.validator";
import {
  ValidateBelgianPostalCode,
  ValidateDutchPostalCode,
  ValidateGermanPostalCode
} from "../validators/postalcode.validator";
import {NotificationService} from "../services/notification.service";
import {ValidateEmail} from "../validators/email.validator";
import {ValidateHouseNumber} from "../validators/housenumber.validator";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ModalContentComponent} from '../components/app-modal/app-modal-content.component';
import {ValidateOTCode} from "../validators/otcode.validator";
import {ValidateInitials} from "../validators/initials.validator";
import {RuleEvaluatorService} from "../services/rule-evaluator.service";
import {ValidateVnum} from "../validators/vnum.validator";
import {ValidateFutureDateInflux} from "../validators/futuredate.influx.validator";

export abstract class DynamicSectionComponent {

  public btnTextNext: string = '<i class="fas fa-angle-right"></i> Volgende';
  public btnTextWait: string = '<i class="fas fa-spinner fa-spin"></i> Bezig met opslaan...';
  public btnTextValue: string = this.btnTextNext;
  public btnClickAllowed: boolean = true;

  public isSubmitted: boolean = false;

  public formGroup!: FormGroup;
  public signUp: SignUp;
  public rows: FormRow[];

  private form: FormDefinition;
  private currentFormType: FormType;
  private currentSectionType: SectionType;
  public section: FormSection;
  bsModalRef?: BsModalRef;
  public documentsInProgressName: string[] = [];

  constructor(private router: Router,
              private formService: FormService,
              private notificationService: NotificationService,
              private modalService: BsModalService,
              public ruleEvaluatorService: RuleEvaluatorService,
              type: SectionType, formType: FormType) {
    this.currentSectionType = type;
    this.currentFormType = formType
  }

  getFormService(): FormService {
    return this.formService;
  }
  public setFormType(formType: FormType) {
    this.formService.setFormType(formType)
    this.currentFormType = formType;
  }

  public setCurrentSectionType(sectionType: SectionType) {
    this.currentSectionType = sectionType;
  }
  public getCurrentSectionType() {
    return this.currentSectionType;
  }

  getCustomSections(): FormSection[] {
    return this.getForm()?.sections.filter((section) => section.type.startsWith('CUSTOM'))
  }

  public getFormType(): FormType {
    return this.currentFormType;
  }

  public isWorkflowFormType(): boolean {
    return this.currentFormType == FormType.PO_APPLICATION || this.currentFormType == FormType.PO_INTAKE || this.currentFormType == FormType.PO_SIGNUP
  }

  public commonNgOnInit() {
    this.setForm();
    this.setSection();

    if (!this.getSection()) {
      this.router.navigate(['/home']);
    }
    this.rows = [];
    if (this.getSection() == null) return;
    let row = new FormRow();
    this.getSection().fields.forEach(fld => {
      row.fields.push(fld);
      if (fld.rowBreak) {
        this.rows.push(row);
        row = new FormRow();
      }
    });
    this.rows.push(row);
  }

  public setForm(): void {
    this.form = (this.formService.getForm());
  }

  public getForm(): FormDefinition {
    if (!this.form) {
      this.setForm();
    }
    return this.form;
  }

  public getRouter(): Router {
    return this.router;
  }

  private getNextSection(): FormSection {
    let currentIndex = -1;
    for (let i=0;i<this.getForm().sections.length;i++) {
      const section: FormSection = this.form.sections[i];
      if (section.enabled) {
        if (this.router.url === this.getLinkForSection(section.type)) {
          currentIndex = i;
        }
        else if (currentIndex >= 0) {
          return section;
        }
      }
    }
    return null;
  }

  public getNextSectionBySection(refSection: FormSection): FormSection {
    let currentIndex = -1;
    for (let i=0;i<this.getForm().sections.length;i++) {
      const section: FormSection = this.form.sections[i];
      if (section.enabled) {
        if (section.type === refSection.type) {
          currentIndex = i;
        }
        else if (currentIndex >= 0) {
          return section;
        }
      }
    }
    return null;
  }

  private getPreviousSection(): FormSection {
    let currentIndex = -1;
    let previousSection = this.getForm().sections[0];
    for (let i=0;i<this.form.sections.length;i++) {
      const section: FormSection = this.form.sections[i];
      if (section.enabled) {
        if (this.router.url === this.getLinkForSection(section.type)) {
          currentIndex = i;
          return currentIndex > 0 ? previousSection : null;
        }
        else {
          previousSection = section;
        }
      }
    }
    return null;
  }

  private getPreviousCustomSection(): FormSection {
    let currentIndex = -1;
    let previousSection = this.getForm().sections[0];
    for (let i=0;i<this.form.sections.length;i++) {
      const section: FormSection = this.form.sections[i];
      if (section.enabled) {
        if (this.router.url === this.getLinkForCustomSection(section.type, i+1)) {
          currentIndex = i;
          return currentIndex > 0 ? previousSection : null;
        }
        else {
          previousSection = section;
        }
      }
    }
    return null;
  }

  public getNextSectionUrl(): string {
    const section: FormSection = this.getNextSection();
    return section != null ? this.getLinkForSection(this.getNextSection().type) : null;
  }

  public getPreviousSectionUrl(): string {
    const section: FormSection = this.getPreviousSection();
    return section != null ? this.getLinkForSection(section.type) : '/';
  }

  public getPreviousCustomSectionUrl(): string {
    const section: FormSection = this.getPreviousCustomSection();
    return section != null ? this.getLinkForCustomSection(section.type, this.getSectionIndexInForm(section.type)+1) : '/';
  }

  getSectionIndexInForm(sectionType: SectionType): number {
    return this.getForm().sections.findIndex(section => section.type === sectionType);
  }


  public back() {
    if (this.currentFormType === 'PO_INTAKE') {
      this.router.navigate([this.getPreviousCustomSectionUrl()]);
    } else {
      this.router.navigate([this.getPreviousSectionUrl()]);
    }
  }

  public goToNext(signUp: SignUp) {
    let section: FormSection = this.getNextSection();
    if (section != null && section.condition != null) {
      let getNext = false;
      switch (section.condition) {
        case "FIRST_YEAR" :
          getNext = signUp.school.educationYear === 1;
          break
        default: break;
      }
      if (getNext) {
        section = this.getNextSectionBySection(section);
      }
    }
    const url = section != null ? this.getLinkForSection(section.type) : '/';
    this.router.navigate([url]);
  }

  public goToNextInWorkflow() {
    let section: FormSection = this.getNextSection();
    const url = section != null ? this.getLinkForSection(section.type) : '/';
    this.router.navigate([url]);
  }

  handleSaveError(err: any) {
    if(err.status == 404) {
      this.notificationService.signupNotFoundError();
    } else if (err.status == 208) {
      this.notificationService.signupAlreadyCompletedError();
    } else if (err.errorCode == 403) {
      this.notificationService.error(err.message, "");
    }
  }

  handleSaveErrorFromDto() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Controle 1e keuze aanmelding',
        text: 'Let op: Uw kind is al aangemeld bij een school van 1e voorkeur binnen uw regio. Ga terug en kies indien mogelijk voor een aanmelding bij een school van 2e voorkeur.',
        routingUrl: "/school"
      }
    };
    this.bsModalRef = this.modalService.show(ModalContentComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Sluiten';
  }


  goToHere(): boolean {
    if (this.formService.getFormType() === "PO_INTAKE") {
      if (this.currentSectionType) {
        this.router.navigate([this.getLinkForCustomSection(this.currentSectionType, this.getSectionIndexInForm(this.currentSectionType)+1)])
      } else {
        const section: FormSection = this.getCurrentSection();
        this.router.navigate([this.getLinkForCustomSection(section.type, this.getSectionIndexInForm(section.type)+1)]);
      }
      return false;
    }
    if (this.currentSectionType) {
      this.router.navigate([this.getLinkForSection(this.currentSectionType)])
    } else {
      const section: FormSection = this.getCurrentSection();
      this.router.navigate([this.getLinkForSection(section.type)]);
    }
    return false;
  }

  public getCurrentSection(): FormSection {
    if (this.getForm() != null) {
      if (this.currentSectionType) {
        return this.getSectionByType(this.currentSectionType)
      }
      for (let i=0;i<this.form.sections.length;i++) {
        if (this.router.url === this.getLinkForSection(this.form.sections[i].type)) {
          return this.form.sections[i];
        }
      }
    }
    return null;
  }

  public getCurrentSectionStep() {
    if (this.getForm() != null) {
      if (this.currentSectionType) {
        return this.getStepNumberByType(this.currentSectionType)
      }
    }
    return null;
  }


  public getLinkForSection(type: SectionType): string {
    return AppStappenplanComponent.getLinkForSection(this.currentFormType,type);
  }
  public getLinkForCustomSection(type: SectionType, index: number): string {
    return AppStappenplanComponent.getLinkForCustomSection(type, index);
  }

  public isSectionEnabled(type: string): boolean {
    return this.getSectionByType(type)?.enabled;
  }

  public getSectionByType(type: string): FormSection {
    for (let i=0;i < this.getForm()?.sections.length;i++) {
      if (this.form.sections[i].type.toString() == type) return this.form.sections[i];
    }
    return null;
  }

  private getStepNumberByType(type: string): any {
    for (let i=0;i < this.getForm().sections.length;i++) {
      if (this.form.sections[i].type.toString() == type) return "Stap " + (i+1) + " van " + this.getForm().sections.length;
    }
    return "";
  }

  public setSection(): void {
    if (!this.section) {
      this.getForm();
      this.section = this.getCurrentSection();
    }
  }

  public setFormSection(section: FormSection): void {
      this.section = section;
  }

  public getSection(): FormSection {
    if (!this.section) {
      this.setSection();
    }
    return this.section;
  }

  public getFieldByVarName(varName: string): FormField {
    this.setSection();
    if (this.section == null) return;
    for (let i=0; i < this.section.fields.length;i++) {
      if (this.section.fields[i].varName === varName) {
        return this.section.fields[i];
      }
    }
    return null;
  }

  public getRequiredPostfix(field: FormField) {
    return field.required ? '*' : '';
  }

  setFormFieldDirty(varName: string) {
    let fld = this.formGroup.controls[varName];
    if (fld) {
      fld.markAsDirty({onlySelf:true});
    }
  }

  getFormValueByVarName(varName: string): any {
    let fld = this.formGroup.controls[varName];
    if (fld) {
      return fld.value;
    }
    return null;
  }
  setFormValueByVarName(varName: string, value: any) {
    let fld = this.formGroup.controls[varName];
    if (fld) {
      fld.setValue(value);
    }
  }
  disableFormFieldByVarName(varName: string) {
    let fld = this.formGroup.controls[varName];
    if (fld) {
      fld.disable();
    }
  }
  enableFormFieldByVarName(varName: string) {
    let fld = this.formGroup.controls[varName];
    if (fld) {
      fld.enable();
    }
  }

  getValueForField(fld: FormField, obj: any): any {
    let val = obj[fld.varName];
    if (fld.varName.indexOf('.') > 0) {
      let prts = fld.varName.split('.');
      let checkobj;
      if (prts[0].indexOf('[') > 0) {
        let openIndex = fld.varName.indexOf('[');
        let closeIndex = fld.varName.indexOf(']');
        let arrayName = fld.varName.substring(0, openIndex);
        let index = +fld.varName.substring(openIndex + 1, closeIndex);
        checkobj = obj[arrayName][index]
      }
      else {
        checkobj = obj[prts[0]]
      }
      if (checkobj && checkobj != null) {
        switch (prts.length) {
          case 2:
            val = checkobj[prts[1]];
            break;
          case 3:
            val = checkobj[prts[1]][prts[2]];
            break;
        }
      }
    }
    if (fld.controlType == ControlType.SELECT && typeof(val) == 'undefined') {
      val = '';
    }
    return val;
  }

  updateControlValue(fld: FormField, obj: any) {
    let val = this.getValueForField(fld,obj);
    let oldVal = this.formGroup.controls[fld.varName].value
    if (oldVal != val) {
      this.formGroup.controls[fld.varName].setValue(val);
    }
  }

  addControlToFormGroup(fld: FormField, obj: any) {
    // fld.validFn = this.checkRulesOnChange;

    if (fld.enabled == false) return;
    if (fld.type.toString() == 'SUBTITLE' || fld.type.toString() == 'INFORMATION' || fld.controlType.toString() == 'INFORMATION'
        || fld.type.toString() == 'HIDDEN') {
      fld.hideLabel = true;
      return;
    }

    // hide label for custom checkbox
    if (fld.type.toString() == 'CUSTOM_CHECK' && fld.controlType.toString() == 'CHECKBOX') {
      fld.hideLabel = true;
    }

    fld.conditionalDisabled = false;

    let val = this.getValueForField(fld,obj);

    let fc = new FormControl(val);
    if (fld.required) {
      if (fld.controlType.toString().indexOf('UPLOAD') < 0) {
        fc.addValidators(Validators.required);
      }
    }

    if (fld.varName === 'bsn') {
      fc.addValidators(ValidateBSN);
    }
    if (fld.varName === 'own') {
      fc.addValidators(ValidateOWN);
    }
    if (fld.varName === 'vnum') {
      fc.addValidators(ValidateVnum);
    }
    if (fld.varName === 'parentOne.initials'
      || fld.varName === 'parentTwo.initials'
      || fld.varName === 'parentThree.initials') {
      fc.addValidators(ValidateInitials);
      fc.addValidators(Validators.maxLength(10));
    }
    if (fld.type.toString() == 'DATE') {
      fc.addValidators(ValidateDate);
    }
    if (fld.type.toString() == 'PHONE_MOBILE') {
      fc.addValidators(ValidatePhone);
    }
    if (fld.type.toString() == 'OT_CODE') {
      fc.addValidators(ValidateOTCode);
    }
    if (fld.type.toString() == 'CLUSTERDATE') {
      fc.addValidators(ValidateClusterDate);
    }
    if (fld.type.toString() == 'FUTUREDATE') {
      if (fld.varName === 'startDate') {
        fc.addValidators(ValidateFutureDateInflux.futureDateInfluxvalidator(this.signUp.schoolYear))
      } else {
        fc.addValidators(ValidateFutureDate);
      }
    }
    if (fld.type.toString() == 'POSTALCODE') {
      fc.addValidators(ValidateDutchPostalCode)
    }
    if (fld.type.toString() == 'IBAN') {
      fc.addValidators(ValidateIBAN)
    }
    if (fld.type.toString() == 'CHECK' && fld.controlType.toString() == 'CHECKBOX') {
      fc.addValidators(ValidateConfirm)
    }
    if (fld.type.toString() == 'CUSTOM_CHECK' && fld.controlType.toString() == 'CHECKBOX' && fld.required) {
      fc.addValidators(ValidateConfirm)
    }
    if (fld.type.toString() == 'EMAIL') {
      fc.addValidators(ValidateEmail)
    }
    // Prefix of student
    if (fld.type.toString() == 'PREFIX') {
      fc.addValidators(Validators.maxLength(10))
    }
    // Prefix of parents
    if (fld.varName === 'parentOne.prefix'
      || fld.varName === 'parentTwo.prefix'
      || fld.varName === 'parentThree.prefix') {
      fc.addValidators(Validators.maxLength(10))
    }
    if (fld.type.toString() == 'HOUSENUMBER') {
      fc.addValidators(ValidateHouseNumber)
    }

    if (fld.type.toString() == 'HOUSENUMBER_ADDITION') {
      fc.addValidators(Validators.maxLength(5))
    }
    this.setPlaceHolder(fld);
    this.formGroup.addControl(fld.varName,fc);
  }

  setPlaceHolder(fld: FormField) {
    switch (fld.type) {
      case FieldType.FUTUREDATE:
      case FieldType.CLUSTERDATE:
      case FieldType.DATE:
        fld.placeHolder = 'dd-mm-jjjj';
        break;
      case FieldType.ZIPCODE:
      case FieldType.POSTALCODE:
        fld.placeHolder = '1234AB of 1234 AB';
        break;
      default:
        fld.placeHolder = '';
        break;
    }
  }

  public removeControlFromFormGroup(varName: string, obj?: any) {
    let fld = this.getFieldByVarName(varName);
    fld.conditionalDisabled = true;
    this.formGroup.removeControl(varName);

    if (obj === undefined) {
      return
    }

    if (varName.includes('customQuestion')) {
      // In het geval van een formGroup
      if (obj["controls"]) {
        obj.value[varName] = null;
      } else {
        // stap object:
        obj.customQuestionField[varName.split('.')[1]] = null;

        obj.customQuestions?.forEach(question => {
          if (question.varName === varName) {
            question.value = null;
          }
        })
      }
    }
  }

  checkControlByVarName(varName: string, obj: any) : void {
    let fld = this.getFieldByVarName(varName);
    if (fld) {
      this.checkControl(fld,obj,null);
    }
  }

  checkControl(fld: FormField, obj: any, signUp: SignUp): void {
    if (fld == null) return;
    let addControl = true;
    if (fld.condition && fld.condition.length > 0) {
      addControl = this.checkCondition(fld);
    }
    if (fld.rulesSet && fld.rulesSet.rules.length > 0) {
      addControl = this.checkRules(fld, signUp);
    }
    if (addControl) {
      fld.conditionalDisabled = false;
      // add if not yet present
      if (!this.formGroup.controls[fld.varName]) {
        this.addControlToFormGroup(fld,obj)
      }
      else {
        this.updateControlValue(fld,obj)
      }
    }
    else {
      this.removeControlFromFormGroup(fld.varName, obj)
    }
  }

  checkCondition(_fld: FormField): boolean {
    // do nothing
    return true;
  }

  checkRules(fld, signUp): boolean {
    return this.ruleEvaluatorService.evaluateRuleSet(fld.rulesSet, signUp, this.formGroup);
  }

  public changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;
    this.btnTextValue = this.btnTextValue === this.btnTextNext ? this.btnTextWait : this.btnTextNext;
  }

  public setValuesFromFormGroup(obj: any) {
    Object.keys(this.formGroup.controls).forEach(ctrl => {
      if (ctrl.indexOf('.') > -1) {
        let prts = ctrl.split('.');
        let checkobj;
        if (prts[0].indexOf('[') > 0) {
          let openIndex = ctrl.indexOf('[');
          let closeIndex = ctrl.indexOf(']');
          let arrayName = ctrl.substring(0, openIndex);
          let index = +ctrl.substring(openIndex + 1, closeIndex);
          checkobj = obj[arrayName][index]
        }
        else {
          checkobj = obj[prts[0]]
        }
        if (checkobj) {
          switch (prts.length) {
            case 2:
              checkobj[prts[1]] = this.formGroup.controls[ctrl].value;
              break;
            case 3:
              checkobj[prts[1]][prts[2]] = this.formGroup.controls[ctrl].value;
              break;
          }
        }
      }
      else {
        obj[ctrl] = this.formGroup.controls[ctrl].value;
      }
      this.formGroup.controls[ctrl].markAsTouched({onlySelf: true});
    })
  }

  countryIsNl(obj: any): boolean {
    return (obj && obj['country'] === 'Nederland');
  }

  prepareSave(obj: any): boolean {
    this.changeButtonText();
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      this.changeButtonText();
      return false;
    }

    this.setValuesFromFormGroup(obj);

    return true;
  }

  initCustomQuestions(obj: any) {
    if (obj.customQuestions && obj.customQuestions.length > 0) {
      if (!obj.customQuestionField) {
        obj.customQuestionField = {};
      }
      obj.customQuestions.forEach(q => {
        let varName = q.varName.substring(q.varName.indexOf('.')+1);
        let fld = this.getFieldByVarName(q.varName)
        let val = q.value;
        if (fld.controlType == 'CHECKBOX' && val == 'false') {
          obj.customQuestionField[varName] = null;
        }
        else {
          obj.customQuestionField[varName] = val;
        }
      });
    }
    if (!obj.customQuestionField) {
      obj.customQuestionField = {};
    }
  }

  prepareCustomQuestionsForSave(obj: any) {
    let customProps = Object.getOwnPropertyNames(obj.customQuestionField);
    obj.customQuestions = [];
    customProps.forEach(prop => {
      let cq = new CustomQuestion()
      cq.varName = 'customQuestionField.' + prop;
      cq.value = obj.customQuestionField[prop];
      obj.customQuestions.push(cq);
    });
  }

  hideRow(row: FormRow): boolean {
    return row.fields
      && (row.fields[0].controlType == 'SELECT_OR_HIDDEN' || row.fields[0].controlType == 'READONLY_OR_HIDDEN')
      && (row.fields[0].options.length == 1 && row.fields[0].options$ == undefined);
  }

  showRow(row: FormRow): boolean {
    if (!row.fields || row.fields.length == 0) {
      return false;
    }
    for (let nr = 0; nr < row.fields.length; nr++) {
      if (this.checkEnabledField(row,nr)) {
        return true;
      }
    }
    return false;
  }

  checkEnabledField(row: FormRow, nr: number) {
    return row.fields && row.fields.length > nr && row.fields[nr] && row.fields[nr].enabled && !row.fields[nr].conditionalDisabled;
  }

  setPostalCodeValidator(fieldName: string, country: string) {
    let pc = this.formGroup.controls[fieldName];
    if (!pc) return;
    if (!country) {
      // default dutch
      pc.setValidators([ValidateDutchPostalCode, Validators.required])
    }
    else if (country === 'Nederland') {
      pc.setValidators([ValidateDutchPostalCode, Validators.required])
    }
    else if (country === 'België') {
        pc.setValidators([ValidateBelgianPostalCode, Validators.required]);
    } else if (country.indexOf('Duitsland') >= 0) {
        pc.setValidators([ValidateGermanPostalCode, Validators.required]);
    } else {
      pc.clearValidators();
    }
    pc.updateValueAndValidity();
  }

  public getVirusWarning(): string {
    return "";
  }

  fieldChanged(fldChanged: FormField) {
    this.getSection().fields.forEach(field => {
      field.rulesSet?.rules.forEach(ruleToEvaluate => {
        if (ruleToEvaluate.field === fldChanged.varName) {
          this.checkRulesForField(field);
        } else if (ruleToEvaluate.field === null && ruleToEvaluate.rules?.some(rule => rule.field === fldChanged.varName)) {
          this.checkRulesForField(field);
        }
      })
    })
  }

  private checkRulesForField(field: FormField) {
    let addControl = this.ruleEvaluatorService.evaluateRuleSet(field.rulesSet, this.signUp, this.formGroup);
    if (addControl) {
      // add if not yet present
      if (!this.formGroup.controls[field.varName]) {
        this.addControlToFormGroup(field, this.formGroup)
      } else {
        this.updateControlValue(field, this.formGroup)
      }
    } else {
      this.removeControlFromFormGroup(field.varName, this.formGroup)
    }
  }

  public uploadDocument(UploadedDocument) {}

  public uploadInProgress(inProgressFiles: string[]) {
    this.documentsInProgressName = inProgressFiles;
    this.btnClickAllowed = inProgressFiles.length <= 0;
    this.btnTextValue = this.btnClickAllowed ? this.btnTextNext : this.btnTextWait;
  }

}
