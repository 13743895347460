import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {CustomQuestion, Signature} from '../../shared/model/signup';
import {TextFormatService} from '../../shared/services/textformat.service';
import {DynamicSectionComponent} from '../../shared/abstract/dynamic-section-component';
import {FormService} from '../../shared/services/form.service';
import {FormType, SectionType} from '../../shared/model/formSection';
import {FormField} from '../../shared/model/formField';
import {NotificationService} from '../../shared/services/notification.service';
import {WorkflowService} from '../../shared/services/workflow.service';
import {Intake, IntakeDTO, Workflow} from '../../shared/model/workflow';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormDefinition} from '../../shared/model/formDefinition';
import {CustomSection} from '../../shared/model/customForm';
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";


@Component({
  selector: 'app-po-custom-intake-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class PoCustomIntakeDynamicComponent extends DynamicSectionComponent implements OnInit {

  btnSignValue: string = '<i class="fas fa-angle-right"></i> Ondertekenen en aanmelding afronden';

  @Input()
  checking: boolean = false;

  @Input()
  sectionType: SectionType;

  @Input()
  customSectionIndex: number;

  workflow: Workflow;
  intake: Intake = {
      customSections: []
  } as Intake;
  formDefinition: FormDefinition;
  selectedFormId: string;
  signature: Signature = {
    customQuestionField: {}
  } as Signature;

  myCustomSection: any;

  constructor(
      router: Router,
      notificationService: NotificationService,
      ruleEvaluatorService: RuleEvaluatorService,
      private formBuilder: FormBuilder,
      private readonly route: ActivatedRoute,
      formService: FormService,
      private workflowService: WorkflowService,
      private textFormatService: TextFormatService,
      modalService: BsModalService) {
    super(router,formService,notificationService,modalService, ruleEvaluatorService, SectionType.CUSTOM1, FormType.PO_INTAKE)
    this.formGroup = this.formBuilder.group({});
    //TODO checken waarom 2 keer?
  }

  ngOnInit() {
    if (this.checking) {
      //TODO hier 1 keer worflow ophalen, en 1 keer intake?
      this.setupForm();
      return false;
    }

    this.route.paramMap.subscribe((param) => {
      if (param.get("index") != null) {
        this.sectionType = this.getSectionTypeFromIndexInForm(Number(param.get("index")))
      } else {
        return false;
      }
      this.setFormItems();
    });

    if (this.customSectionIndex != null) {
      this.setFormItems();
    }
  }

  setFormItems(): void {
    this.setFormType(FormType.PO_INTAKE);
    this.formGroup = this.formBuilder.group({});
    this.setFormSection(this.getSectionByType(this.sectionType))
    this.setupForm();
  }


  setupForm() {
    // Check of workflow al aanwezig is met intakeID
    if (this.workflow && this.workflowService.getWorkflow().intakeId) {
      this.getIntakeCall()
    } else {
      this.getWorkflowCall();
    }

  }
  getWorkflowCall(): void {
    this.workflowService.getWorkflowByWorkflowId(this.workflowService.getWorkflow().id).subscribe((workflow) => {
      this.workflow = workflow
      this.getIntakeCall()
    });
  }

  getIntakeCall(): void {
    // Check of intake gevuld is.
    if (this.intake.customSections.length === 0) {
      this.workflowService.getIntakeByWorkflowId(this.workflow).subscribe((intake) => {
        this.workflowService.setIntake(intake);
        this.intake = intake;
        this.getRest();
      });
    } else {
      this.getRest();
    }
  }

  getRest(): void {
    this.selectedFormId = this.workflow.intake.formId;

    if (this.getFormService().getForm() === null) {
      this.getFormService().getFormById(this.selectedFormId).subscribe(form => {
        this.formDefinition = form;
        this.getFormService().setForm(form);
        this.setCurrentSectionTypeAndIndex();
        this.constructSections();
      });
    } else {
      this.formDefinition = this.getFormService().getForm();
      this.setCurrentSectionTypeAndIndex();
      this.constructSections();
    }
  }

  getSectionIndexInForm(sectionType: SectionType): number {
    return this.formDefinition.sections.findIndex(section => section.type === sectionType);
  }

  getSectionTypeFromIndexInForm(index: number): SectionType {
    index = index - 1;

    if (this.getForm()) {
      return this.getForm().sections[index].type
    }

    return this.formDefinition.sections[index].type;

  }

  setCurrentSectionTypeAndIndex(): void {
    if (this.sectionType) {
      this.setCurrentSectionType(this.sectionType)
    } else {
      this.setCurrentSectionType(this.formDefinition?.sections?.filter((section) => section.type.startsWith("CUSTOM"))[0].type)
    }
    this.customSectionIndex = this.getSectionIndexInForm(this.getCurrentSectionType());
  }

  constructSections(): void {
    this.initCustomSections();
    this.fillIntakeCustomQuestionsByForm(this.intake.customSections[this.customSectionIndex]); //TODO mogelijk boolean wel opslaan

    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      this.checkControl(fld, this.myCustomSection, null);
    });
  }

  initCustomSections(): void {
    if (this.intake.customSections === null) {
      this.intake.customSections = []
    }

    if (this.intake.customSections[this.customSectionIndex] === null || this.intake.customSections[this.customSectionIndex] === undefined) {
      this.intake.customSections[this.customSectionIndex] = {
        customQuestionField: undefined,
        customQuestions: [],
        name: "",
        type: undefined
      }
    }
  }

  fillIntakeCustomQuestionsByForm(customSection: CustomSection): void {
    let newCustomSection: CustomSection = {} as CustomSection;

    newCustomSection.type = this.formDefinition.sections[this.customSectionIndex].type;
    newCustomSection.customQuestionField = {}

    this.formDefinition.sections[this.customSectionIndex].fields.forEach((f) => {
      let formFieldVarname = f.varName.substring(f.varName.indexOf(".")+1);
      newCustomSection.customQuestionField[formFieldVarname] = '';
      customSection.customQuestions.forEach((q) => {
        if (q.varName.substring(q.varName.indexOf(".")+1) === formFieldVarname) {
          newCustomSection.customQuestionField[formFieldVarname] = q.value;
        }
      })
    });

    this.myCustomSection = newCustomSection;
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    return checkOk;
  }

  convertMyCustomSection() {
    let customSection = new CustomSection();
    customSection.customQuestions = [];
    Object.keys(this.myCustomSection.customQuestionField).forEach((obj) => {
      let field = new CustomQuestion();
      field.varName = 'customQuestionField.' + obj;
      field.value = this.myCustomSection.customQuestionField[obj];
      customSection.customQuestions.push(field);
    })
    customSection.type = this.myCustomSection.type
    return customSection;
  }

  save(): boolean {
    if (!this.prepareSave(this.myCustomSection)) return false;

    this.intake.customSections[this.customSectionIndex] = this.convertMyCustomSection()
    let intakeDto = {} as IntakeDTO;
    intakeDto.formId = this.getFormService().getForm().id;
    intakeDto.lastStep = this.sectionType
    intakeDto.customSections = this.intake.customSections

    this.workflowService.saveIntake(intakeDto).subscribe((intake) => {
      this.isSubmitted = false;
      this.changeButtonText();
      this.intake = intake;

      let newSection = this.getNextSectionBySection(this.getCurrentSection()).type;
      if ( newSection === 'SIGN') {
        this.getRouter().navigate(['/po/intake/stap/ondertekening']);
        return true;
      }

      // Get index of new section
      let newSectionIndex = (this.getSectionIndexInForm(newSection) + 1);
      this.getRouter().navigate(['/po/intake/stap/', newSectionIndex]);
    })
  }

  changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;
    this.btnTextValue === this.btnTextNext ? this.btnTextValue = this.btnTextWait : this.btnTextValue = this.btnTextNext;
  }


}
