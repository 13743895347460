import { Component, OnInit  } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SignUp} from '../../shared/model/signup';
import { SignUpService } from '../../shared/services/signup.service';
import {StatusService} from "../../shared/services/status.service";
import {WorkflowService} from "../../shared/services/workflow.service";
import {Workflow} from "../../shared/model/workflow";
import {Status} from "../../shared/model/status";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  styles: ['i.warning { color: #f0af31; background-color: white} .error { color: red; background-color: white;} .errorbutton { background-color: white; margin-left:5px}']
})
export class HomeComponent implements OnInit {
  public portalType: string;
  public formId: string;
  public signUps: SignUp[] = [];
  public workflows: Workflow[] = [];
  public intakeByParent: boolean;

  constructor(private router: Router,
              private readonly route: ActivatedRoute,
              private workflowService: WorkflowService,
              private signUpService: SignUpService,
              private statusService: StatusService,
) { }

  ngOnInit() {
    this.formId = this.route.snapshot.paramMap.get('fid');

    this.statusService.getStatus().subscribe((status: Status) => {
      this.portalType = status.portalType;
      this.intakeByParent = status.intakeByParent
      if (this.statusService.getPortalType() === null || this.statusService.getPortalType() === undefined) {
        this.statusService.setPortalType(status.portalType);
      }

      if (this.formId !== undefined && this.formId !== null) {
        sessionStorage.setItem("formulier", this.formId);
      } else if (sessionStorage.getItem("formulier") !== null && sessionStorage.getItem("formulier") !== undefined) {
        this.formId = sessionStorage.getItem("formulier");
      }

      this.signUpService.setSignUp({} as SignUp);
      this.workflowService.setWorkflow({} as Workflow, true);
      this.workflowService.setRegistration(undefined);

      if (this.isPo) {
        this.getWorkflows();
      }
      else {
        this.getSignUps();
      }
    })
  }

  get isPo() {
    return this.portalType == "PO";
  }

  getSignUps(): void {
    this.signUpService.getSignUps().subscribe((data) => {
      this.signUps = data;
    });
  }
  getWorkflows(): void {
    this.workflowService.getWorkflows().subscribe((data) => {
      this.workflows = data;
    });
  }

}
