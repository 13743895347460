import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Router} from '@angular/router';
import {Signature, SignUp} from '../../shared/model/signup';
import {SignUpService} from '../../shared/services/signup.service';
import {TextFormatService} from "../../shared/services/textformat.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormService} from "../../shared/services/form.service";
import {FormType, SectionType} from "../../shared/model/formSection";
import {ConditionType, FormField} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {StatusService} from "../../shared/services/status.service";
import {SchoolService} from "../../shared/services/school.service";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";


@Component({
  selector: 'app-sign-dynamic',
  templateUrl: './sign-dynamic.component.html'
})
export class SignDynamicComponent extends DynamicSectionComponent implements OnInit {

  btnSignValue: string = '<i class="fas fa-angle-right"></i> Ondertekenen en aanmelding afronden';

  signUp: SignUp;
  signature: Signature = {
    customQuestionField: {}
  } as Signature;

  constructor(
    router: Router,
    notificationService: NotificationService,
    ruleEvaluatorService: RuleEvaluatorService,
    private formBuilder: FormBuilder,
    formService: FormService,
    private signUpService: SignUpService,
    private textFormatService: TextFormatService,
    modalService: BsModalService,
    private schoolService: SchoolService,
    private statusService: StatusService) {
    super(router,formService,notificationService, modalService, ruleEvaluatorService, SectionType.SIGN, FormType.SIGNUP)
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.signUp = this.signUpService.getSignUp();

    if (this.signUp.signature !== undefined && this.signUp.signature !== null) {
      this.signature = this.signUp.signature;
      if (!this.signature.customQuestionField) {
        this.signature.customQuestionField = {};
      }
    }
    this.signature.signedAt = this.getCurrentDateTime();

    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      this.checkControl(fld,this.signature, this.signUp);
    });
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.FIRST_YEAR:
          if (this.signUp.school.educationYear == undefined ||  this.signUp.school.educationYear > 1) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
      }
    });
    return checkOk;
  }

  getCurrentDateTime(): string {
    const currentDT = new Date();

    return currentDT.getDate() + '-' +
      (currentDT.getMonth() + 1) + '-' +
      currentDT.getFullYear() + ' ' +
      currentDT.getHours() + ':' +
      (currentDT.getMinutes() < 10 ? '0' + currentDT.getMinutes() : currentDT.getMinutes());
  }

  save(): boolean {
    if (!this.prepareSave(this.signature)) return false;

    this.prepareCustomQuestionsForSave(this.signature);

    this.signature.signedBy = this.textFormatService.capitalizeFirstLetter(this.signature.signedBy);

    this.signUpService.saveSignature(this.signature).subscribe((messageDto) => {
      this.getRouter().navigate(['/bedankt']);
    }, (err) => {
      if (err.error === 'Not allowed to create another first choice signup') {
        this.handleSaveErrorFromDto();
      }
      else {
        this.getRouter().navigate(['/home']);
        this.handleSaveError(err);
      }
    });
    return true;
  }

  changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;
    this.btnTextValue === this.btnTextNext ? this.btnTextValue = this.btnTextWait : this.btnTextValue = this.btnTextNext;
  }

}
