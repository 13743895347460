import {AbstractControl} from "@angular/forms";

export class ValidateFutureDateInflux {

  static futureDateInfluxvalidator(schoolYear: string)  {
    return(control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (value.length === 10) {
        const day = Number(value.substr(0, 2));
        const month = Number(value.substr(3, 2)) - 1;
        const year = Number(value.substr(6, 4));

        const hasValidSeparators = value.charAt(2) == '-' && value.charAt(5) == '-';

        const date = new Date(year, month, day);
        const isValid = date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
        const isInFuture = date.getTime() > new Date().getTime();

        const schoolYearStart = Number(schoolYear.split("/")[0]);
        const schoolYearEnd = Number(schoolYear.split("/")[1]);
        const schoolYearDateFrom = new Date(schoolYearStart, 7, 1); // month 7 because it starts counting from 0 (So August is used)
        const schoolYearDateTo = new Date(schoolYearEnd, 7, 1); // month 7 because it starts counting from 0 (So August is used)
        const isWithinSchoolYear = date > schoolYearDateFrom && date < schoolYearDateTo

        if (isValid && isInFuture && isWithinSchoolYear && hasValidSeparators) {
          return null;
        }
      }

      return {'appFutureDateInflux': { value: value } };
    }

  }
}
