import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {FormService} from "../../shared/services/form.service";
import {AddressService} from "../../shared/services/address.service";
import {CountryService} from "../../shared/services/country.service";
import {SchoolService} from "../../shared/services/school.service";
import {TextFormatService} from "../../shared/services/textformat.service";
import {FormType} from "../../shared/model/formSection";
import {NotificationService} from "../../shared/services/notification.service";
import {WorkflowService} from "../../shared/services/workflow.service";
import {SignUpService} from "../../shared/services/signup.service";
import {ParentDynamicComponent} from "../../shared/abstract/parent.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";

@Component({
  selector: 'app-po-parent-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class PoParentDynamicComponent extends ParentDynamicComponent implements OnInit {

  @Input()
  checking: boolean = false;

  constructor(router: Router,
              formService: FormService,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              formBuilder: FormBuilder,
              signUpService: SignUpService,
              workflowService: WorkflowService,
              addressService: AddressService,
              countryService: CountryService,
              schoolService: SchoolService,
              textFormatService: TextFormatService,
              modalService: BsModalService) {
    super(router,formService,notificationService,ruleEvaluatorService,formBuilder,signUpService,workflowService,addressService,countryService,schoolService,textFormatService,FormType.PO_APPLICATION, modalService)
  }

  ngOnInit() {
    super.genericNgOnInit();
  }
}
